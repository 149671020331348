






















import Vue from 'vue';
import Component from 'vue-class-component';
import {Emit, Model, Prop} from "vue-property-decorator";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";

@Component({})
export default class AutoComplete extends Vue {
	private value = '';

	private title = '';

	private toggle = false;

	private vars: Array<AutoCompleteDTO> = []

	@Prop({default: "autocomplete"})
	private alias!: string;

	@Prop({default: ''})
	private validatePattern!: string;

	@Prop()
	private label!: string;

	@Prop({default: ""})
	private placeHolder!: string;

	@Prop({default: null})
	private length!: number | null;

	@Prop()
	private items!: Array<AutoCompleteDTO>;

	@Prop({default: '100px'})
	private maxHeight!: string;

	@Model("input")
	private model!:string;

	@Emit("input")
	updateValue(value: string) : string{
		return value;
	}

	public created(): void {
		const res = this.items.find(item => item.value == this.model);
		this.value = res ? res.value : '';
		this.title = res ? res.title : '';
		this.vars = this.items;
	}


	private setValue(item: AutoCompleteDTO): void {
		if (this.length !== null) {
			item.value = item.value.substr(0, this.length);
		}

		this.value = item.value;
		this.title = item.title;
		if (this.items.find(i => i.title === this.title)) {
			this.updateValue(this.value);
		}
		this.toggle = false;
	}

	search(): void {
		this.toggle = true;
		this.value = this.title
		this.vars = this.items.filter(v => v.title.toLowerCase().includes(this.title.toLowerCase().trim()) || v.value.toLowerCase().includes(this.title.toLowerCase().trim()))
		this.updateValue(this.value);
	}
}
