export default class AddressDTO {
    public id: number | null = null;

    public address1: string | null = null;

    public address2: string | null = null;

    public city: string | null = null;

    public foreignAddress: string | null = null;

    public isResidential = true;

    public country: string | null = null;

    public state: string | null = null;

    public zipCode!: string;

    public createDate?: Date;

    public updateDate?: Date;

    public verified = false;

    public fullAddress: string | null = null;

    constructor(init?: Partial<AddressDTO>) {
        Object.assign(this, init);
    }

    static isEmpty(val: AddressDTO | null): boolean {
        if (val == null) return true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (val.address1 == null || val.address1 == "") && (val.foreignAddress == "" || val.foreignAddress == null)
    }
}

