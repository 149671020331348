




























































import {Component, Vue} from "vue-property-decorator";
import {LocationType, UpdateBranchPayload} from "@/components/profile/branches/BranchDtos";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import {prettyEnum} from "@/utils/StringUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import {ifValid} from "@/utils/ComponentUtils";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import AddressDTO from "@/dto/AddressDTO";

const AppModule = namespace("App");

@Component({
	components: {PortalCheckbox, PortalAddress, PortalDate, PortalSelect, PortalTextarea, PortalInput}
})
export default class BranchEditor extends Vue {

	private req: UpdateBranchPayload  = new UpdateBranchPayload();

	private message = "";

	private successful = false;

	private dataIsReady = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted(): void {
		this.loadBranch();
	}

	private loadBranch() {
		this.startLoading();
		CompanyService.loadBranchById<UpdateBranchPayload>(this.$route.params.branchId).then(
			res => {
				const data = res.data;
				data.legalAddress = !res.data.legalAddress ? new AddressDTO() : res.data.legalAddress;
				data.mailingAddress = !res.data.mailingAddress ? new AddressDTO(): res.data.mailingAddress;
				data.actualAddress = !res.data.actualAddress ? new AddressDTO(): res.data.actualAddress;
				data.registrationAgentAddress = !res.data.registrationAgentAddress ? new AddressDTO() : res.data.registrationAgentAddress;
				this.req = data;
				this.successful = true;
				this.dataIsReady = true;
				this.stopLoading();
			},
			err => this.processError(err)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	private buildOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(prettyEnum(LocationType.DOMESTIC)).value(LocationType.DOMESTIC).build(),
			SelectOption.builder().title(prettyEnum(LocationType.FOREIGN)).value(LocationType.FOREIGN).build()
		]
	}

	private update(): void {
		this.message = "";
		ifValid(this, () => {
			this.startLoading();
			this.req.headQuarter = false

			CompanyService.updateBranch(this.req).then(
				() => {
					this.message = "Data successfully updated";
					this.stopLoading()
				},
				err => this.processError(err)
			)

		})
	}
}
