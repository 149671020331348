import CompanyDTO from "@/dto/company/CompanyDTO";
import AddressDTO from "@/dto/AddressDTO";
import EmploymentDTO from "@/dto/employee/EmploymentDTO";

export abstract class Branch {
    title: string | null = null;

    description: string | null = null;

    locationType: LocationType | null = LocationType.DOMESTIC;

    legalAddress: AddressDTO | null = new AddressDTO();

    mailingAddress: AddressDTO | null = new AddressDTO();

    actualAddress: AddressDTO | null = new AddressDTO();

    registrationAgentAddress: AddressDTO | null = new AddressDTO();

    registrationDate: Date | null = null;

    headQuarter = false;

    private employments: Array<EmploymentDTO> = [];

    get getEmployments(): Array<EmploymentDTO> {
        return this.employments;
    }
}

export class CreateBranchPayload extends Branch {
    companyId: number | null = null
}

export default class BranchDto extends Branch {
    id: number | null = null;

    company: CompanyDTO | null = null;
}

export class UpdateBranchPayload extends Branch {
    id: number | null = null;

    employmentIds: Array<number> = []
}


export enum LocationType {
    DOMESTIC = "DOMESTIC",
    FOREIGN = "FOREIGN"
}